<!--  -->
<template>
  <div class='tixi round bj-col-white pad'>
    <h3>课程体系</h3>
    <p>行业“专家”授课包括：药学服务工作应当掌握的专业领域法律法规、专业知识和专业技能。药物合理使用的技术规范，常见病症的诊疗指南与应用；国内外药学领域的新理论、新知识、新技术……</p>
    <div class="arr">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div>
          <img src="../assets/img/mingcheng.png" alt="">
          <h4>{{item.mingcheng}}</h4>
        </div>
        <div>
          <img src="../assets/img/ren.png" alt="">
          <span>{{item.zjr}}</span>
        </div>
        <div>
          <img src="../assets/img/zhicheng.png" alt="">
          <span>{{item.zhiwu}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../api/home'

export default {
  components: {},
  data() {
    return {
      list: []
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getTixi()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    getTixi () {
      Api.getTixi().then(res => {
        if (res.data.code == 1) {
          this.list = res.data.data
        } else {
          this.$message.error("课程体系获取失败")
        }
      })
    }
  },
}
</script>

<style lang='less' scoped>
.tixi {
  > p {
    margin: 30px 0;
    color: @default;
  }
  .arr {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 32%;
      margin-right: 1%;
      background-image: url('../assets/img/dibu.png');
      background-size: 100% 100%;
      padding: 15px 20px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 15px;
      > div {
        display: flex;
        align-items: center;
        h4,
        span {
          flex: 1;
          margin-left: 10px;
        }
        span {
          color: #999;
        }
      }
      > div:nth-child(2) {
        margin: 10px 0;
      }
    }
  }
}
</style>